<template>
  <div class="container">
    <div class="container_details" v-if="check == true">
      <div class="latest_movement">
        <h4 class="container_number">
          {{ $t("container_number") }}
          <span class="container_num">{{ this.bookingDetails.container_number }}</span>
        </h4>
        <Explanation />
        <h5>{{ $t("Deliverd") }}</h5>
        <lastMovements v-if="lastMovements != []" :lastMovements="lastMovements" />
      </div>

      <!-- Start Booking information -->
      <bookingDetails :bookingDetails="bookingDetails" />
      <!-- End Booking information -->
      
      <!-- Start container information -->
      <containerDetails :bookingDetails="bookingDetails" />
      <!-- End container information -->

      <!-- Start factory information -->
      <factoryDetails :factoryDetails="factoryDetails" />
      <!-- End factory information -->
    </div>
    <div class="select" v-else>
      <h4>{{error}}</h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import bookingDetails from "@/components/includes/container/booking_information.vue";
import containerDetails from "@/components/includes/container/container_information.vue"
import factoryDetails from "@/components/includes/container/factory_information.vue";
import Explanation from "@/components/includes/container/explanation.vue";
import lastMovements from "@/components/includes/container/last_movements.vue"

export default {
  name: "container_number",
  props: ["container_id"],
  data() {
    return {
      check: "",
      error: "",
      container_number: "",
      bookingDetails: {},
      factoryDetails: {},
      lastMovements: [],
    };
  },
  components: {
    bookingDetails,
    containerDetails,
    factoryDetails,
    Explanation,
    lastMovements
  },
  computed: {
    childData() {
      return this.container_id;
    },
  },
  methods: {
    containersDetailsData() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get(`/booking/container/${this.childData}`).then(({ data }) => {
        this.check = data.status;
        this.bookingDetails = data.data.bookingDetails;
        this.factoryDetails = data.data.factoryDetails;
        this.lastMovements = data.data.lastMovements;
        // console.log(this.bookingDetails);
      }).catch((error) => {
        this.error = this.$t("Please select container");
        return error;
      });
    },
  },
  created() {
    this.containersDetailsData();
  },
  watch: {
    childData() {
      this.containersDetailsData()
    }
  }
};
</script>

<style>
.select h4 {
  font-family: "semibold";
  text-align: center;
  margin: 2rem 0;
}
</style>