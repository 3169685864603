<template>
  <section class="profile">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <Sidebar />
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <ProfileForm />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from '@/components/layouts/Sidebar.vue'
import ProfileForm from '@/components/includes/Profile/ProfileForm.vue'

export default {
    name: "profile-page",
    components: {
      Sidebar,
      ProfileForm
    }
}
</script>

<style>

</style>