<template>
  <section class="login" v-if="Form.company_id != null && email != null">
    <form @submit.prevent="SendOtp">
      <div class="form-group">
        <label for="">{{ $t("Enter code consist of 6 digits") }}</label>
        <input
          type="number"
          name=""
          id=""
          class="form-control"
          placeholder="OTP"
          v-model="Form.otp"
        />
      </div>
      <button type="submit" class="btn submit_btn">{{ $t("send") }}</button>
    </form>
    <img src="@/assets/media/login.jpg" alt="login" />
  </section>
  <NotFound />
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import NotFound from '@/components/NotFound.vue';

export default {
  name: "otp-page",
  data() {
    return {
      Form: {
        company_id: localStorage.getItem("company_id"),
        otp: "",
      },
      email: localStorage.getItem("email"),
    };
  },
  components: {
    NotFound
  },
  methods: {
    SendOtp() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/verify-otp", this.Form)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.$router.push("/reset_password");
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "top-end",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
};
</script>

<style scoped>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>