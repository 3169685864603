<template>
  <div class="info" v-if="bookingDetails != null">
    <div class="head_title">{{ $t("booking_information") }}</div>
    <ul>
      <li class="data">
        <span class="title">{{ $t("shipping_agency") }} :</span>
        <span class="text">{{ bookingDetails.shipping_agency }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("customs_certificate_number") }} :</span>
        <span class="text">{{ bookingDetails.custom_certificate_number }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("type_of_service") }} :</span>
        <span class="text">{{ bookingDetails.type_of_service }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("type_of_action") }} :</span>
        <span class="text">{{ bookingDetails.type_of_action }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("discharge_date") }} :</span>
        <span class="text">{{ bookingDetails.discharge_date }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("permit_end_date") }} :</span>
        <span class="text">{{ bookingDetails.permit_end_date }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props: ["bookingDetails"]
};
</script>

<style>
</style>