<template>
  <Booking :send-data="handleData" />
  <ContainerDetails :container_id="handleData(id)" />
</template>

<script>
import Booking from '@/components/includes/booking/booking.vue';
import ContainerDetails from '@/components/includes/container/container_details.vue';

export default {
  name: "booking-page",
  components: {
    Booking,
    ContainerDetails,
  },
  data() {
    return {
      id: ""
    }
  },
  methods: {
    handleData(data) {
      this.id = data;
      // console.log(data);
      return data;
    }
  }
}
</script>