<template>
  <section class="profile">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <Sidebar />
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <table id="table">
            <thead>
              <tr>
                <th scope="col">{{ $t("employee name") }}</th>
                <th scope="col">{{ $t("employee position") }}</th>
                <th scope="col">{{ $t("E-Mail") }}</th>
                <th scope="col">{{ $t("Phone") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(employee, index) in employees" :key="index">
                <td :data-label="$t('employee name')">{{ employee.name }}</td>
                <td :data-label="$t('employee position')">{{ employee.job }}</td>
                <td :data-label="$t('E-Mail')">{{ employee.email }}</td>
                <td :data-label="$t('Phone')">{{ employee.phone }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { employeesData } from "@/api/profile/employees.js";
import Sidebar from '@/components/layouts/Sidebar.vue'
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
    name: "profile-page",
    components: {
      Sidebar,
    },
    data() {
      return {
        check: "",
        employees: [],
      };
    },
    mounted() {
      employeesData().then((response) => {
        this.check = response.data.status;
        this.employees = response.data.data;
        // console.log(this.employees);
        return response;
      });
    },
    updated() {
    $("#table").DataTable({
      retrieve: true,
      pageLength : 4,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("employees"),

        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("From") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("employees"),
      },
    });
  },
}
</script>

<style>

</style>