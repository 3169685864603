import { createRouter, createWebHistory } from "vue-router";
import store from "../src/store/index.js";

//import website
import homePage from "../src/views/home.vue";
import bookPage from "../src/views/booking.vue";
import loginPage from "../src/views/auth/login.vue";
import setPasswordPage from "../src/views/auth/set_password.vue";
import ForgetPasswordPage from "../src/views/auth/forget_password.vue";
import OtpPage from "../src/views/auth/otp.vue";
import resetPasswordPage from "../src/views/auth/reset_password.vue";
import NotFound from "../src/views/error.vue";

import profilePage from "../src/views/profile/profile.vue";
import employeePage from "../src/views/profile/employee.vue";
import passwordPage from "../src/views/profile/password.vue";
import bookingPage from "../src/views/profile/booking.vue";

const routes = [
  // Website routes
  { path: "/", component: homePage, name: "home" },
  { path: "/book", component: bookPage, name: "book" },
  {
    path: "/login",
    component: loginPage,
    name: "login",
    meta: { guest: true },
  },
  {
    path: "/set_password",
    component: setPasswordPage,
    name: "setPassword",
    meta: { guest: true },
  },
  {
    path: "/forget_password",
    component: ForgetPasswordPage,
    name: "ForgetPassword",
    meta: { guest: true },
  },
  { path: "/otp", component: OtpPage, name: "Otp", meta: { guest: true } },
  {
    path: "/reset_password",
    component: resetPasswordPage,
    name: "resetPassword",
    meta: { guest: true },
  },
  {
    path: "/profile",
    component: profilePage,
    name: "profile",
    meta: { requiresAuth: true },
  },
  {
    path: "/employee",
    component: employeePage,
    name: "employee",
    meta: { requiresAuth: true },
  },
  {
    path: "/password",
    component: passwordPage,
    name: "password",
    meta: { requiresAuth: true },
  },
  {
    path: "/booking",
    component: bookingPage,
    name: "booking",
    meta: { requiresAuth: true },
  },
  { path: "/404", component: NotFound },
  { path: "/:catchAll(.*)", redirect: "/404" },
];

const router = createRouter({
  routes,
  history: createWebHistory(),
  hashbang: false,
  mode: "history",
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // Scroll to the top of the page
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/profile");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
