<template>
  <section class="sponsers" id="sponsors" v-if="sponsers.length">
    <div class="container">
      <h2 class="title_head">{{$t("our sponsors")}}</h2>
      <swiper
        :pagination="true"
        :modules="modules"
        :spaceBetween="30"
        :loopFillGroupWithBlank="true"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            loopFillGroupWithBlank: true,
          },
        }"
        class="sponsersSwiper mySwiper"
      >
        <swiper-slide
          class="card_sponser"
          v-for="(sponser, index) in sponsers"
          :key="index"
        >
          <img :src="sponser.image" alt="sponser" />
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { sponsersData } from "@/api/Home/sponsers.js";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination } from "swiper";

export default {
  name: "sponsers-home",
  data() {
    return {
      check: "",
      sponsers: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
  created() {
    sponsersData().then((response) => {
      this.check = response.data.status;
      this.sponsers = response.data.data;
      //   console.log(this.header);
      return response;
    });
  },
};
</script>
