<template>
  <section class="container containers_details">
    <div class="containers_table" v-if="check == true">
      <h4 class="booking_number">
        {{ $t("booking_number") }}
        <span class="track_number">{{ this.track }}</span>
      </h4>
      <h5>{{ $t("list_container_booking") }}</h5>
      <containers :containers="containers" @button-clicked="handleButtonClick" />
    </div>
    <div class="not_found" v-else>
        <lottie-player src="https://assets6.lottiefiles.com/packages/lf20_jippjent.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
    </div>
  </section>
</template>

<script>
import { containersData } from "@/api/container_table.js";
import containers from "@/components/includes/container_table.vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default {
  name: "booking_container",
  props: ["sendData"],
  data() {
    return {
      check: "",
      containers: [],
    };
  },
  components: {
    containers,
  },
  mounted() {
    containersData().then((response) => {
      this.check = response.data.status;
      this.containers = response.data.data;
      // console.log(this.check);
      return response;
    });
  },
  created() {
    this.track = this.$route.query.track;
  },
  methods: {
    handleButtonClick(container_id) {
      this.sendData(container_id);
    },
  },
  updated() {
    $("#table").DataTable({
      retrieve: true,
      pageLength : 4,
      language: {
        search: this.$t("search"),
        lengthMenu: this.$t("show") + " _MENU_ " + this.$t("containers"),

        paginate: {
          first: this.$t("first"),
          last: this.$t("last"),
          next: this.$t("next"),
          previous: this.$t("prev"),
        },
        info:
          this.$t("show") +
          " _START_ " +
          this.$t("From") +
          " _END_ " +
          this.$t("to") +
          " _TOTAL_ " +
          this.$t("containers"),
      },
    });
  },
};
</script>

<style>
.containers_details {
  margin-top: 10rem;
}
</style>