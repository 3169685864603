<template>
  <section class="header">
    <img :src="header.image" :alt="header.title">
    <div class="container">
      <div class="content">
        <h6>{{ header.title }}</h6>
        <h2>{{ header.description }}</h2>
        <Track />
      </div>
    </div>
  </section>
</template>

<script>
import { headerData } from "@/api/Home/header.js";
import Track from './track.vue';

export default {
  name: "header-home",
  data() {
    return {
      check: "",
      header: {},
    };
  },
  components: {
    Track
  },
  created() {
    headerData().then((response) => {
      this.check = response.data.status;
      this.header = response.data.data;
      //   console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
