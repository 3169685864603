<template>
  <div class="sidebar">
    <router-link to="/profile">{{$t("your basic information")}}</router-link>
    <router-link to="/employee">{{$t("your employee data")}}</router-link>
    <router-link to="/password">{{$t("your password")}}</router-link>
  </div>
</template>

<script>
export default {
    name: "sidebar-component"
}
</script>

<style>

</style>