<template>
  <div class="info" v-if="bookingDetails != null">
    <div class="head_title">{{ $t("container_information") }}</div>
    <ul>
      <li class="data">
        <span class="title">{{ $t("container_number") }} :</span>
        <span class="text">{{ bookingDetails.container_number }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("navigational_torrent") }} :</span>
        <span class="text">{{ bookingDetails.sail_number }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("Type") }} :</span>
        <span class="text">{{ bookingDetails.type }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props: ["bookingDetails"]
};
</script>

<style>
</style>