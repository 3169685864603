<template>
  <footer id="contact">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12">
          <img src="../../assets/media/logo.png" alt="logo" class="logo" />
          <a class="contact_text" :href="`mailto:${settings.email}`">{{settings.email}}</a>
          <a class="contact_text" :href="`tel:${settings.phone}`">{{settings.phone}}</a>
        </div>
        <div class="col-lg-4 col-md-6 col-12">
          <h4>{{ $t("other pages") }}</h4>
          <router-link to="/terms">{{ $t("terms and condition") }}</router-link>
          <router-link to="/privacy">{{ $t("privacy policy") }}</router-link>
        </div>
        <div class="col-lg-4 col-md-12 col-12">
          <h4>{{ $t("Need a help") }}</h4>
          <form v-on:submit.prevent="SubmitForm">
            <input
              type="email"
              name=""
              id=""
              v-model="Form.email"
              class="form-control"
              :placeholder="$t('Enter your mail')"
            />
            <textarea
              name=""
              id=""
              v-model="Form.message"
              cols="30"
              rows="3"
              class="form-control"
              :placeholder="$t('Enter your message')"
            ></textarea>
            <button type="submit" class="btn submit_btn">
              {{ $t("submit") }}
            </button>
          </form>
        </div>
      </div>
      <div class="row">
        <ul class="socialicon">
          <li>
            <a v-if="settings.facebook" target="_blank" :href="settings.facebook"
              ><i class="fa-brands fa-facebook-f icon"></i
            ></a>
          </li>
          <li>
            <a v-if="settings.twitter" target="_blank" :href="settings.twitter"
              ><i class="fa-brands fa-twitter icon"></i
            ></a>
          </li>
          <li>
            <a v-if="settings.linkedin" target="_blank" :href="settings.linkedin">
              <i class="fa-brands fa-linkedin-in icon"></i
            ></a>
          </li>
          <li>
            <a v-if="settings.instagram" target="_blank" :href="settings.instagram"
              ><i class="fa-brands fa-instagram icon"></i
            ></a>
          </li>
          <li>
            <a v-if="settings.whatsapp" target="_blank" :href="settings.whatsapp"
              ><i class="fab fa-whatsapp icon"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { settingsData } from "@/api/settings.js";

export default {
  name: "footer-component",
  data() {
    return {
      Form: {
        email: "",
        message: "",
      },
      settings: {}
    }
  },
  methods: {
     SubmitForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios
        .post("/contact_us", this.Form)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.Form = {
              email: "",
              message: "",
            };
          } else {
            Swal.fire({
              type: "error",
              position: "top-end",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            type: "error",
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
  created() {
    settingsData().then((response) => {
      this.check = response.data.status;
      this.settings = response.data.data;
        // console.log(this.settings);
      return response;
    });
  },
};
</script>

<style scoped>
  textarea {
    resize: none;
  }
</style>
