<template>
  <div class="explanation">
    <div class="step">
      <i class="fa-regular fa-circle-dot"></i>
      <span>{{ $t("pickup") }}</span>
    </div>
    <div class="step">
      <i class="fa-regular fa-circle-dot"></i>
      <span>{{ $t("in_transist") }}</span>
    </div>
    <div class="step">
      <i class="fa-solid fa-circle-check"></i>
      <span>{{ $t("Delivery") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "explanation-step",
};
</script>

<style>
</style>