<template>
    <Header />
    <About />
    <Services />
    <Choose />
    <Sponsors />
    <Reviews />
</template>

<script>
import Header from "@/components/includes/Home/header.vue";
import About from '@/components/includes/Home/about.vue';
import Services from '@/components/includes/Home/services.vue';
import Choose from '@/components/includes/Home/choose.vue';
import Sponsors from '@/components/includes/Home/sponsers.vue';
import Reviews from '@/components/includes/Home/reviews.vue';

export default {
    name: "index-page",
    components: {
        Header,
        About,
        Services,
        Choose,
        Sponsors,
        Reviews
    },
}
</script>