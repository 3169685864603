import axios from "axios";

export const containersData = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let track_number = params.track; // "some_value"
    return axios
      .get("/booking/track?order_number="+track_number)
      .then((response) => {
        // console.log(track_number);
        // console.log(response.data);
        return response;
      });
  };