<template>
  <!-- Start Nav top -->
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="logo navbar-brand">
        <img src="@/assets/media/logo.png" alt="logo" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="/#about">{{$t("About us")}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#services">{{$t("our services")}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#choose">{{$t("why choose us")}}</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link" href="/#sponsors">{{$t("our sponsors")}}</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link" href="/#contact">{{$t("contact us")}}</a>
          </li>
        </ul>
        <div class="data">
          <DropdownAuth v-if="auth" />
          <LoginButton v-else />
          <LangButton />
        </div>
      </div>
    </div>
  </nav>
  <!-- End Nav top -->
</template>

<script>
import LangButton from '../includes/Buttons/LangButton.vue';
import LoginButton from '../includes/Buttons/LoginButton.vue';
import DropdownAuth from '../includes/Buttons/DropdownAuth.vue';

export default {
  name: "nav-bar",
  data() {
    return {
      show: true,
      auth: false,
    };
  },
  components: {
    LangButton,
    LoginButton,
    DropdownAuth
  },
  created() {
    this.auth = this.$store.getters['isAuthenticated'];
    // console.log("Auth : " + this.auth);
  }
};
</script>
