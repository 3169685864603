<template>
  <div class="info" v-if="factoryDetails != null">
    <div class="head_title">{{ $t("factory_information") }}</div>
    <ul>
      <li class="data">
        <span class="title">{{ $t("factory_name") }} :</span>
        <span class="text">{{ factoryDetails.name }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("factory_branch") }} :</span>
        <span class="text">{{ factoryDetails.city }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("arrival_date") }} :</span>
        <span class="text">{{ factoryDetails.arrival_date }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("factory_email") }} :</span>
        <span class="text">{{ factoryDetails.email }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("factory_number") }} :</span>
        <span class="text">{{ factoryDetails.number }}</span>
      </li>
      <li class="data">
        <span class="title">{{ $t("factory_address") }} :</span>
        <span class="text">{{ factoryDetails.address }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    props: ["factoryDetails"]
};
</script>

<style>
</style>