<template>
  <div class="last_movements" v-for="(movement, index) in lastMovements" :key="index">
    <div class="date_time">
        <div class="date">{{movement.date}}</div>
        <div class="time">{{movement.time}}</div>
    </div>
    <div class="status">
        <i v-if="movement.status == 0 || movement.status == 1" class="fa-regular fa-circle-dot"></i>
        <i v-if="movement.status == 2" class="fa-solid fa-circle-check"></i>
    </div>
    <div class="description">
        <p class="title">{{movement.description}}</p>
        <p class="place">{{movement.place_of_activity}}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: "last_movements",
    props: ["lastMovements"]
}
</script>

<style>

</style>