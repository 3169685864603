<template>
  <div class="not_found">
    <h2 class="not_found_text">{{ $t("not_found") }}</h2>
    <lottie-player
      src="https://assets3.lottiefiles.com/packages/lf20_NlLnID.json"
      background="transparent"
      speed="1"
      loop
      autoplay
    ></lottie-player>
  </div>
</template>

<style scoped>
.not_found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}
.not_found_text {
    font-family: "semibold";
}
lottie-player {
  width: 700px;
  height: 700px;
}
</style>
