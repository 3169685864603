<template>
  <section class="login">
    <form @submit.prevent="ForgetPassword">
      <div class="form-group">
        <label for="">{{ $t("E-Mail") }}</label>
        <input
          type="email"
          name=""
          id=""
          class="form-control"
          placeholder="example@gmail.com"
          v-model="Form.email"
        />
      </div>
      <button type="submit" class="btn submit_btn">{{ $t("send OTP") }}</button>
    </form>
    <img src="@/assets/media/login.jpg" alt="login" />
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "login-page",
  data() {
    return {
      Form: {
        email: "",
      },
    };
  },
  methods: {
    ForgetPassword() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/forget-password", this.Form)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            localStorage.setItem('company_id', data.data.data.company.id);
            localStorage.setItem('email', data.data.data.company.email);
            this.$router.push("/otp");
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "top-end",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
};
</script>
