<template>
  <section class="login" v-if="Form.email != null">
    <form @submit.prevent="ResetPassword">
      <div class="form-group">
        <label for="">{{ $t("password") }}</label>
        <input
          type="password"
          name=""
          id=""
          class="form-control"
          placeholder="*****************"
          v-model="Form.password"
        />
      </div>
      <div class="form-group">
        <label for="">{{ $t("confirm_password") }}</label>
        <input
          type="password"
          name=""
          id=""
          class="form-control"
          placeholder="*****************"
          v-model="Form.password_confirmation"
        />
      </div>
      <button type="submit" class="btn submit_btn">{{ $t("save") }}</button>
    </form>
    <img src="@/assets/media/login.jpg" alt="login" />
  </section>
  <NotFound />
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import NotFound from "../../components/NotFound.vue";

export default {
  name: "reset-page",
  data() {
    return {
      Form: {
        email: localStorage.getItem("email"),
        password: "",
        password_confirmation: "",
      },
    };
  },
  components: {
    NotFound,
  },
  methods: {
    ResetPassword() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/reset-password", this.Form)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.$router.push("/login");
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "top-end",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
