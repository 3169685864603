<template>
  <section class="profile">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <Sidebar />
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <UpdatePassword />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Sidebar from '@/components/layouts/Sidebar.vue';
import UpdatePassword from '@/components/includes/Profile/UpdatePassword.vue';

export default {
    name: "profile-page",
    components: {
      Sidebar,
      UpdatePassword
    }
}
</script>

<style>

</style>