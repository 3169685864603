<template>
  <table id="table">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{{ $t("Container Number") }}</th>
        <th scope="col">{{ $t("Type") }}</th>
        <th scope="col">{{ $t("Latest Status") }}</th>
        <th scope="col">{{ $t("Date") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(container, index) in containers" :key="index">
        <td :data-label="$t('choose container')">
          <div class="form-check">
            <label class="form-check-label">
              <input
                type="radio"
                class="form-check-input"
                name="conatiner"
                id=""
                @click="PassContainerId(container.id)"
                :value="container.id"
              />
            </label>
          </div>
        </td>
        <td :data-label="$t('Container Number')">{{ container.container_id }}</td>
        <td :data-label="$t('Type')">{{ container.container_type }}</td>
        <td :data-label="$t('Latest Status')">{{ container.last_status }}</td>
        <td :data-label="$t('Date')">{{ formatDate(container.date) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
// import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "table_container",
  props: ["containers"],
  data() {
    return {
    };
  },
  methods: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    PassContainerId(container_id) {
      this.$emit('button-clicked', container_id);
    }
  },
};
</script>

<style>
</style>