<template>
  <a class="lang" v-if="langStorage == 'en'" @click="changeLocale('ar')">
        <img class="icon" src="@/assets/media/egypt.png" alt="egypt" />
        <span>{{ $t("arabic") }}</span>
      </a>
      <a
        class="lang"
        v-else-if="langStorage == 'ar'"
        @click="changeLocale('en')"
      >
        <img
          class="icon"
          src="@/assets/media/united-states.png"
          alt="united-states"
        />
        <span>{{ $t("english") }}</span>
      </a>
      <a class="lang" v-else @click="changeLocale('ar')">
        <img class="icon" src="@/assets/media/egypt.png" alt="egypt" />
        <span>{{ $t("arabic") }}</span>
      </a>
</template>

<script>
export default {
  name: "nav-bar",
  data() {
    return {
      show: true,
      auth: false,
      langStorage: localStorage.getItem("lang"),
    };
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", locale);
      // this.$forceUpdate('lang',locale);
      localStorage.setItem("lang", locale);
      location.reload();
      // console.log(locale);
    },
  },
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$i18n.locale);
  },
};
</script>