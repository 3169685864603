import axios from "axios";

export const choosePageData = () => {
  return axios.get("/page/choose us").then((response) => {
    // console.log(response.data);
    return response;
  });
};

export const chooseData = () => {
    return axios.get("/choose-us").then((response) => {
      // console.log(response.data);
      return response;
    });
  };
  
