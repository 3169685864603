<template>
  <section class="login">
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="">{{ $t("E-Mail") }}</label>
        <input
          type="email"
          name=""
          id=""
          class="form-control"
          placeholder="example@gmail.com"
          v-model="Form.email"
        />
      </div>
      <div class="form-group">
        <label for="">{{ $t("password") }}</label>
        <input
          :type="passwordFieldType"
          name=""
          id=""
          class="form-control"
          placeholder="*****************"
          v-model="Form.password"
        />
        <button
          class="btn show_password"
          type="button"
          @click="switchVisibility"
          v-html="eye"
        ></button>
      </div>
      <router-link to="/forget_password" class="forget_btn">{{
        $t("forget_password")
      }}</router-link>
      <button type="submit" class="btn submit_btn">{{ $t("login") }}</button>
    </form>
    <img src="@/assets/media/login.jpg" alt="login" />
  </section>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "login-page",
  data() {
    return {
      Form: {
        email: "",
        password: "",
      },
      passwordFieldType: "password",
      eye: "<i class='fa-solid fa-eye'></i>",
    };
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.eye =
        this.eye == "<i class='fa-solid fa-eye'></i>"
          ? "<i class='fa-solid fa-eye-slash'></i>"
          : "<i class='fa-solid fa-eye'></i>";
    },
    async login() {
      let response = await this.$store.dispatch("Login", this.Form);
      // console.log(response);
      if (this.Form.email === "" || this.Form.password === "") {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 1500,
        });
      } else {
        try {
          if (response.data.status == true) {
            // console.log("message" + response.data.message);
            // console.log("status" + response.data.status);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: response.data.message,
              timer: 1500,
            });
            this.Form = {
              email: "",
              password: "",
            };
            this.$router.go("/profile");
            this.error = null;
          } else if (response.data.status == false) {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: response.data.message,
              timer: 1500,
            });
          }
        } catch (err) {
          console.log(err.response.data.message)
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response.data.message,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
