<template>
  <section class="services" id="services" ref="services">
    <div class="container">
        <h2 class="title_head">{{$t("our services")}}</h2>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-12 mb-3" v-for="(service, index) in services" :key="index">
                <div class="service_card">
                    <img :src="service.image" :alt="service.title">
                    <p class="title">{{service.title}}</p>
                    <p class="text">{{service.description}}</p>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { servicesData } from "@/api/Home/services.js";

export default {
  name: "services-home",
  data() {
    return {
      check: "",
      services: [],
    };
  },
  created() {
    servicesData().then((response) => {
      this.check = response.data.status;
      this.services = response.data.data;
      //   console.log(this.header);
      return response;
    });
  },
};
</script>