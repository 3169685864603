<template>
  <router-link to="/login" class="btn login_btn">{{$t("login")}}</router-link>
</template>

<script>
export default {

}
</script>

<style>

</style>