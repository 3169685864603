<template>
  <div class="dropdown">
    <button
      class="btn dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{name}}
    </button>
    <ul class="dropdown-menu">
      <li><router-link class="dropdown-item" to="/profile"><i class="fa-solid fa-address-card"></i> {{$t("main profile")}}</router-link></li>
      <li><router-link class="dropdown-item" to="/booking"><i class="fa-solid fa-book-bookmark"></i> {{$t("all booking")}}</router-link></li>
      <li><a class="dropdown-item" @click="removeToken"><i class="fa-solid fa-right-from-bracket"></i> {{$t("logout")}}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: localStorage.getItem("name"),
      email: localStorage.getItem("email"),
    };
  },
  methods: {
    removeToken: function () {
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("address"),
      localStorage.removeItem("phone")
      this.$router.go("/login");
    },
  },
};
</script>

<style></style>
