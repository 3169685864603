<template>
  <section class="login">
    <form @submit.prevent="SetPasswordForm">
      <div class="form-group">
        <label for="">{{ $t("password") }}</label>
        <input
          type="password"
          name=""
          id=""
          class="form-control"
          placeholder="*****************"
          v-model="Form.password"
        />
      </div>
      <div class="form-group">
        <label for="">{{ $t("confirm_password") }}</label>
        <input
          type="password"
          name=""
          id=""
          class="form-control"
          placeholder="*****************"
          v-model="Form.password_confirmation"
        />
      </div>
      <button type="submit" class="btn submit_btn">{{ $t("save") }}</button>
    </form>
    <img src="@/assets/media/login.jpg" alt="login" />
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      Form: {
        token: this.$route.query.token,
        password: "",
        password_confirmation: "",
      },
    };
  },
  methods: {
    SetPasswordForm() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
      this.loading = true; //the loading begin
      axios
        .post("/set-password", this.Form)
        .then((data) => {
          if (data.data.status == true) {
            //Perform Success Action
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.$router.push("/login");
          } else {
            // console.log(data);
            //Perform Success Action
            Swal.fire({
              type: "error",
              position: "top-end",
              icon: "error",
              title: data.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            type: "error",
            position: "top-end",
            icon: "error",
            title: error.response.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          // console.log(error);
          // this.$router.push({ path: "/teachers" });
        })
        .finally(() => (this.loading = false));
      {
        //Perform action in always
      }
    },
  },
};
</script>

<style></style>
