<template>
  <section class="choose" id="choose">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-12">
                <img :src="choose.image" class="choose_image" :alt="choose.title">
            </div>
            <div class="col-lg-7 col-md-12 col-12">
                <h2 class="title">{{$t("why choose us")}}</h2>
                <p class="text">{{choose.description}}</p>
                <div class="choose_list" v-for="(list, index) in choose_list" :key="index">
                    <img :src="list.image" :alt="list.title">
                    <h5 class="title_list">{{list.title}}</h5>
                    <p class="text_list">{{list.description}}</p>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import { choosePageData } from "@/api/Home/choose.js";
import { chooseData } from "@/api/Home/choose.js";

export default {
  name: "services-home",
  data() {
    return {
      checkChoose: "",
      check: "",
      choose: {},
      choose_list: [],
    };
  },
  created() {
    choosePageData().then((response) => {
      this.checkChoose = response.data.status;
      this.choose = response.data.data;
      //   console.log(this.header);
      return response;
    });
    chooseData().then((response) => {
      this.check = response.data.status;
      this.choose_list = response.data.data;
      //   console.log(this.header);
      return response;
    });
  },
};
</script>
