<template>
  <section class="reviews" v-if="reviews.length">
    <div class="container">
      <h2 class="title_head">{{$t("our clients")}}</h2>
      <swiper
        :navigation="true"
        :modules="modules"
        :spaceBetween="30"
        :loopFillGroupWithBlank="true"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          300: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            loopFillGroupWithBlank: false,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            loopFillGroupWithBlank: false,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            loopFillGroupWithBlank: true,
          },
        }"
        class="reviewsSwiper mySwiper"
      >
        <swiper-slide
          v-for="(review, index) in reviews"
          :key="index"
        >
        <div class="card_review">
          <div class="user_data">
            <img :src="review.image" alt="review" />
            <p class="name">{{review.title}}</p>
          </div>
          <p class="text">{{review.description}}</p>
          <p class="date">{{formatDate(review.created_at)}}</p>
        </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { reviewsData } from "@/api/Home/reviews.js";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
// Import Swiper styles
import "swiper/css";
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Navigation } from "swiper";
import moment from "moment";

export default {
  name: "sponsers-home",
  data() {
    return {
      check: "",
      reviews: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  setup() {
    return {
      modules: [Autoplay, Navigation],
    };
  },
  created() {
    reviewsData().then((response) => {
      this.check = response.data.status;
      this.reviews = response.data.data;
      //   console.log(this.header);
      return response;
    });
  },
};
</script>
