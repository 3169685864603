import axios from "axios";
export default {
  async Register(context, Form) {
    let response = await axios.post("/register", Form);
    localStorage.setItem("token", response.data.data.token);
    // commit("setUser", { user: response });
    return response;
  },
  async Login({ commit }, Form) {
    try {
      let response = await axios.post("/login", Form);
      localStorage.setItem("token", response.data.data.token);
      localStorage.setItem("name", response.data.data.company.name);
      localStorage.setItem("email", response.data.data.company.email);
      localStorage.setItem("address", response.data.data.company.address);
      localStorage.setItem("phone", response.data.data.company.phone);
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },

  logout({ commit }) {
    commit("logout");
  },
};
