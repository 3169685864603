<template>
    <div class="track_order">
        <div class="form-group">
            <input type="number" name="" id="" v-model="number" class="form-control" :placeholder="$t('enter_booking_number')">
            <button type="button" @click.stop.prevent:="clickRouter(path)" class="btn"><i class="fa-regular fa-map"></i> {{$t("track")}}</button>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "track-component",
    data () {
        return {
            path: "/book/",
            number: ""
        }
    },
    methods: {
        clickRouter(link) {
            if (this.number == "") {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: this.$t("please_enter_booking_number"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else {
                this.$router.push({ path: link, query: { track: this.number }});
            }
        }
    }
}
</script>