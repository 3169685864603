<template>
  <form v-on:submit.prevent="SubmitForm" class="profile_form">
    <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
                <label for="">{{$t("password")}}</label>
                <input type="password" name="" id="" class="form-control" v-model="Form.password" placeholder="**************" required>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
            <div class="form-group">
                <label for="">{{$t("confirm_password")}}</label>
                <input type="password" name="" id="" class="form-control" v-model="Form.password_confirmation" placeholder="**************" required>
            </div>
        </div>
        <div class="col-12">
            <button type="submit" class="btn submit_btn">{{$t("save changes")}}</button>
        </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            Form: {
                password: "",
                password_confirmation: ""
            }
        }
    },
    methods: {
        SubmitForm() {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
        axios
            .put("/profile/update_information", this.Form)
            .then((data) => {
            // console.log(data);
            if (this.Form.password == '' || this.Form.password_confirmation == '') {
                //Perform Success Action
                Swal.fire({
                    position: "top-end",
                    icon: "warning",
                    title: this.$t("Please dictate all fields"),
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            else if (data.data.status == true) {
                //Perform Success Action
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: data.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                location.reload();
            } else {
                //Perform Success Action
                Swal.fire({
                    type: "error",
                    position: "top-end",
                    icon: "error",
                    title: data.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            })
            .catch((error) => {
                Swal.fire({
                    type: "error",
                    position: "top-end",
                    icon: "error",
                    title: error.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
            })
            .finally(() => {
            //Perform action in always
            });
        },
    },
}
</script>

<style>

</style>